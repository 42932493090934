import React, { useState } from "react";

const WalletButton = (props) => {
  const [connected, setConnected] = React.useState(false);

  if (props.activeAddress) {
    return (
      <React.Fragment>
        <button
          id="disconnectArConnect"
          className="btn btn-light nav-link"
          onClick={props.disconnectCallBack}
        >
          {props.activeAddress.substring(0, 6) +
            "..." +
            props.activeAddress.substring(6, 2)}
        </button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <button
          id="connectArConnect"
          className="btn btn-primary nav-link text-light"
          onClick={props.connectCallBack}
        >
          ArConnect Wallet
        </button>
      </React.Fragment>
    );
  }
};

export default WalletButton;
