import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Rewards from "./pages/Rewards";
import Verify from "./pages/Verify";
import { arConnectPermissions, apiConfig } from "./arweave-config.js";
import WalletButton from "./components/WalletButton";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  const [session, setSession] = React.useState({});
  const [activeAddress, setActiveAddress] = React.useState("");
  const [connected, setConnected] = React.useState(false);

  /* functions for arconnect wallet */
  const connect = async () => {
    await window.arweaveWallet.connect(arConnectPermissions);
    const address = await window.arweaveWallet.getActiveAddress();
    setActiveAddress(address);
    setConnected(true);
    console.log("connected!", activeAddress);
  };

  const disconnect = async () => {
    await window.arweaveWallet.disconnect();
    setActiveAddress("");
    setConnected(false);
    console.log("disconnected!");
  };

  React.useEffect(() => {
    if (window.arweaveWallet && connected === false) {
      disconnect();
    }
  }, [connected]);

  React.useEffect(() => {
    window.addEventListener("walletSwitch", (event) => {
      const getAddress = async () => {
        const address = await window.arweaveWallet.getActiveAddress();
        setActiveAddress(address);
      };
      getAddress();
    });
  });

  return (
    <Router>
      <nav className="navbar navbar-expand-sm">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <b>Crypto Slang Gang</b>
          </Link>
          <div className="nav" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link btn text-dark mx-1" to="/rewards">
                  Rewards
                </Link>
              </li>
              <li className="nav-item">
                <WalletButton
                  activeAddress={activeAddress}
                  connectCallBack={connect}
                  disconnectCallBack={disconnect}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="rewards"
            element={<Rewards activeAddress={activeAddress} />}
          />
          <Route exact path="/verify" element={<Verify />} />
        </Routes>
        <footer className="text-center small my-5">
          (c) 2022, Zero Knowledge Collective, SAS
        </footer>
      </div>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
