import React from "react";
import Claims from "../components/Claims";

export default function Rewards(props) {
  return (
    <div>
      <div>
        <h1 className="my-3">Rewards</h1>
        <p className="lead">
          Participants in the auction can claim free storage and other rewards.
        </p>
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title">Auction Bidders</h4>
                <p className="card-text">
                  Anyone who took part in the Crypto Slang Gang auction is
                  eligible for 1 GB of storage on Akord, and if you were one of
                  the top 5 bidders you’ll get 5 GB.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title">NFT Winers</h4>
                <p className="card-text">
                  If you were lucky enough to win one of the Gang, you’ll also
                  receive a fine art print signed by the artist Serge Seidlitz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Claims
        activeAddress={props.activeAddress}
        transferNftCallBack={props.transferNftCallBack}
      />
    </div>
  );
}
