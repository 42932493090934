import React from "react";
import Gallery from "../components/Gallery";
import csg from "../csg-addresses.json";

export default function Home({ data }) {
  return (
    <div className="jumbotron py-3">
      <img
        src="/art/crypto_gang.png"
        className="my-3 image-fluid rounded"
        width="100%"
      />
      <h2 className="my-3">Introducing</h2>
      <p className="lead mb-5">
        The Crypto Slang Gang is an exploration into web3, with each character
        representing popular memes or slang within the space. Each artwork has
        also been printed as a 50x50cm art giclée print, hand signed by Serge,
        and postage will be accessible worldwide.
      </p>
      <Gallery
        nfts={csg.winners.filter((element) => element.ticker == "CSG")}
      />
    </div>
  );
}
