exports.apiConfig = {
  host: "localhost",
  port: "1984",
  protocol: "http",
  url: "http://localhost:1984",
};
exports.arConnectPermissions = [
  "ACCESS_ADDRESS",
  "SIGN_TRANSACTION",
  "SIGNATURE",
  "ACCESS_PUBLIC_KEY",
  "ACCESS_ALL_ADDRESSES",
  "ENCRYPT",
  "DECRYPT",
];
