import React from "react";
import { Buffer } from "buffer";
import Arweave from "arweave";
import { apiConfig } from "../arweave-config.js";

const arweave = Arweave.init(apiConfig);

const verifyClaim = async (claim) => {
  try {
    const claimJson = JSON.parse(Buffer.from(claim, "base64").toString());
    const claimBuffer = new TextEncoder("utf-8").encode(claimJson.personalInfo);
    const signatureBuffer = arweave.utils.b64UrlToBuffer(claimJson.signature);
    const verification = await arweave.crypto.verify(
      claimJson.publicKey,
      claimBuffer,
      signatureBuffer
    );
    const personalInfoJson = JSON.parse(
      Buffer.from(claimJson.personalInfo, "base64").toString()
    );

    console.log("VALID SIGNATURE: " + verification);
    return personalInfoJson;
  } catch (error) {
    console.log("Unable to verify the signature:" + error);
    return null;
  }
};

export default function Verify(props) {
  const [encodedClaim, setEncodedClaim] = React.useState("");
  const [validClaim, setValidClaim] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);

  const handleChange = async (event) => {
    const value = event.target.value;
    setEncodedClaim(value);
    setShowResult(false);
  };

  const handleVerify = async (event) => {
    const valid = await verifyClaim(encodedClaim);
    setValidClaim(valid);
    setShowResult(true);
  };

  return (
    <div>
      <div>
        <h1 className="my-3">Verify</h1>
        <p className="lead">
          Copy and paste the Crytpo Slang Gang encoded claim below.
        </p>
        <div className="row ">
          {showResult > 0 && validClaim && (
            <div className="col-12">
              <div className="alert alert-success">
                <h4>Looks good!</h4>
                <pre>{validClaim.address}</pre>
                <pre>{validClaim.email}</pre>
              </div>
            </div>
          )}
          {showResult > 0 && !validClaim && (
            <div className="col-12">
              <div className="alert alert-danger">
                <h4>Invalid Claim!</h4>
              </div>
            </div>
          )}
          <div className="col-12">
            <p>
              <textarea
                className="form-control my-3"
                onChange={handleChange}
                rows="10"
              ></textarea>
            </p>
            <p>
              <button className="btn btn-primary" onClick={handleVerify}>
                Check Claim
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
