import React from "react";

const NftCard = {
  maxWidth: "320px",
};

const NftImage = {
  display: "block",
  maxWidth: "320px",
  width: "auto",
  height: "auto",
  padding: "10px",
};

function contractUrl(contract) {
  return `https://v2.viewblock.io/arweave/contract/${contract}`;
}

function nftUrl(url) {
  return `/art/${url}`;
}

function Card(nft, index, transferNftCallBack) {
  return (
    <div className="col" key={index}>
      <div className="card m-3 mx-auto" style={NftCard}>
        <a href={contractUrl(nft.mintTxId)} target="_blank" rel="noreferrer">
          <img
            src={nftUrl(nft.image)}
            alt={nft.title}
            target="_blank"
            className="card-img-top"
          />
        </a>
        <div className="card-body">
          <h5 className="card-title">{nft.title}</h5>
          <p className="card-text small">{nft.description}</p>
        </div>
      </div>
    </div>
  );
}

const Gallery = (props) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
      {props.nfts.map(function (nft, index) {
        return Card(nft, index);
      })}
    </div>
  );
};

export default Gallery;
