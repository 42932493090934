import React, { useState } from "react";
import EmailSection from "./EmailSection";
import AddressSection from "./AddressSection";
import SignSection from "./SignSection";
import OwnerList from "./OwnerList";

import csgAddresses from "../csg-addresses.json";

function findNftFor(address) {
  return csgAddresses.winners.filter((element) => element.owner == address);
}

// 0 - no claims available
// 1 - claim for free storage
// 2 - claim for free storage and physical nft
function findAddress(address) {
  if (csgAddresses.winners.find((element) => element.owner == address)) {
    return 2;
  }

  if (csgAddresses.bidders.find((element) => element == address)) {
    return 1;
  }
  return 0;
}

const Claims = (props) => {
  const [state, setState] = React.useState({});
  const claimType = findAddress(props.activeAddress);

  const dataCallback = (data) => {
    const new_state = state;
    if (data.email) new_state.email = data.email;
    if (data.address) new_state.address = data.address;
    setState(new_state);
  };

  const childGetState = () => {
    return state;
  };

  function handleNftSelect(evt) {
    props.transferNftCallBack(evt.target.value);
  }

  if (props.activeAddress) {
    if (claimType == 0)
      return (
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">
              🤷🏻‍♂️ Sorry, we don&apos;t have any rewards for this address.
            </h4>
            <div>Address: {props.activeAddress}</div>
            <div>Claim Type: {findAddress(props.activeAddress)}</div>
          </div>
        </div>
      );
    else
      return (
        <div>
          <OwnerList
            nfts={findNftFor(props.activeAddress)}
            onNftSelect={handleNftSelect}
          />
          <div className="card">
            <div className="card-header">
              <h4>🌶 You are elgible for rewards!</h4>
              <div>Address: {props.activeAddress}</div>
              <div>Claim Type: {claimType}</div>
            </div>
            <div className="card-body">
              <EmailSection dataCallback={dataCallback} />
              <hr />
              {claimType === 2 && (
                <AddressSection dataCallback={dataCallback} />
              )}
              <SignSection childGetState={childGetState} />
            </div>
          </div>
        </div>
      );
  } else {
    return <p className="m-3">Connect your wallet to check.</p>;
  }
};

export default Claims;
