import React from "react";

const stylresNftCard = {
  //   maxWidth: "540px",
};

const NftImage = {
  display: "block",
  maxWidth: "120px",
  width: "auto",
  height: "auto",
  padding: "10px",
};

function nftUrl(url) {
  return `/art/${url}`;
}

const OwnerList = (props) => {
  return (
    <React.Fragment>
      <div className="row py-5">
        <div className="col">
          <h4>🎩 Your Crypto Slang Gang NFT&apos;s</h4>

          <div>
            {props.nfts.map(function (nft, index) {
              return (
                <div className="card my-3" key={index} style={stylresNftCard}>
                  <div className="row g-0">
                    <div className="col-md-4">
                      <a
                        href={
                          "http://v2.viewblock.io/arweave/contract/" +
                          nft.mintTxId
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="img-fluid rounded-start"
                          src={nftUrl(nft.image)}
                          alt={nft.title}
                          target="_blank"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{nft.title}</h5>
                        <p className="card-text">{nft.description}</p>
                        <p className="small">Owner: {nft.owner}</p>
                        {/* <button
                          onClick={props.onNftSelect}
                          className="btn btn-primary"
                          value={nft.mintTxId}
                        >
                          Transfer
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OwnerList;
