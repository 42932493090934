import React from "react";

const EmailSection = (props) => {
  const [emailAddress, setEmailAddress] = React.useState("");

  const updateEmailAddress = (event) => {
    const value = event.target.value;
    setEmailAddress(value);
    props.dataCallback({ email: value });
  };

  return (
    <div className="row m-3">
      <h4>🎁 Free Akord Storage</h4>
      <p>
        Please provide the email address to your Akord account. If you
        don&apos;t have an Akord account,{" "}
        <a href="http://app.akord.com">let&apos;s get started</a>!
      </p>
      <p>
        <input
          type="email"
          className="form-control"
          id="emailAddress"
          placeholder="you@example.com"
          onChange={updateEmailAddress}
        ></input>
      </p>
    </div>
  );
};

export default EmailSection;
