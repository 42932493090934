import React from "react";

const AddressSection = (props) => {
  const [shippingAddress, setShippingAddress] = React.useState("");

  const updateShippingAddress = (event) => {
    const value = event.target.value;
    setShippingAddress(value);
    props.dataCallback({ address: value });
  };

  return (
    <div className="row m-3">
      <h4>💌 Auction owner received first signed prints</h4>
      <p>
        Congratulations on winning the NFT! Claim your physical print, signed
        and stamped by Serge.
      </p>
      <p>
        Please provide your shipping address as it is to appear on the envelope.
      </p>
      <p>
        <textarea
          className="form-control"
          id="shippingAddress"
          placeholder={"John Smith\n123 Main Street\nHometown St 10000"}
          onChange={updateShippingAddress}
          rows="5"
        ></textarea>
      </p>
    </div>
  );
};

export default AddressSection;
