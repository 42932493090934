import React from "react";
import { Buffer } from "buffer";

const uint8ToBase64 = (arr) => Buffer.from(arr).toString("base64");

const SignSection = (props) => {
  const [signedClaim, setSignedClaim] = React.useState("");
  const [copySuccess, setCopySuccess] = React.useState("");
  const textAreaRef = React.useRef(null);

  const sign = async () => {
    const state = props.childGetState();

    // get address and public key
    const address = await window.arweaveWallet.getActiveAddress();
    const publicKey = await window.arweaveWallet.getActivePublicKey();

    // sign the personal info as base64
    const personalInfo = btoa(JSON.stringify(state));
    var claim_buff = new TextEncoder("utf-8").encode(personalInfo);
    const sig = await window.arweaveWallet.signature(claim_buff, {
      name: "RSA-PSS",
      saltLength: 32, //the length of the salt
    });

    // construct the claim
    const claim = {
      personalInfo: personalInfo,
      address: address,
      publicKey: publicKey,
      signature: uint8ToBase64(sig),
    };
    const encoded_claim = btoa(JSON.stringify(claim));
    setSignedClaim(encoded_claim);
    // setSignedClaim(JSON.stringify(claim, null, 3));

    setCopySuccess(false);
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  return (
    <div className="row m-3">
      <h4 className="py-3">✍️ Sign your Claim</h4>
      <p>
        <b>Please review your email and/or shipping address.</b> Then sign your
        information with your ArConnect wallet.
      </p>
      <p>
        <button className="btn btn-primary" onClick={sign}>
          Sign with Arconnect
        </button>
      </p>
      <h4 className="py-3">📧 Send your claim</h4>
      <p>
        <span>
          For email verification, please send your claim from the box below
          to&nbsp;
        </span>
        <a
          href={
            "mailto:support@akord.com?subject=Crypto Slang Gang Rewards&body=Hello Akord, %0D%0A%0D%0APlease find my claim below:%0D%0A%0D%0A" +
            signedClaim
          }
        >
          support@akord.com
        </a>
        {". "}
        <span>
          Click the email link to generate a preloaded email or copy to your
          clipboard with the button below.
        </span>
      </p>
      <p>
        <textarea
          className="form-control my-3"
          id="claimTextArea"
          ref={textAreaRef}
          rows="10"
          value={signedClaim}
          readOnly={true}
        ></textarea>
        <button className="btn btn-primary" onClick={copyToClipboard}>
          Copy to Clipboard
        </button>
        <span className="mx-3">{copySuccess}</span>
      </p>
    </div>
  );
};

export default SignSection;
